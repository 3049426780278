import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import classnames from 'classnames'
import { screen } from '../../../../../utils/index'
import PlayIconImage from '../../../../../images/media-kit/PlayIconImage.png'
import TestimonialSlider from '../TestimonialSlider/TestimonialSlider'
import VideoWithTitle from '../VideoWithTitle'

const isEven = (value) => value % 2 === 0

const Inner = styled.div`
  ${tw`
    my-0
    mx-auto
    pl-3
    pr-3
    xl:pl-0
    xl:pr-0
  `}

  max-width: 1100px;
`

const Poster = styled.img`
  ${tw`
    w-full
  `}

  height: 450px;
  object-fit: cover;
`

const PlayIcon = styled.div`
  ${tw`
    absolute
    cursor-pointer
  `}
  width: 100px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const VideoContainer = styled.div`
  ${tw`
    relative
    z-10
  `}

  video {
    ${tw`
      h-full
      w-full
      cover
    `}
    min-height: 618.75px;
  }
`
const CloseButton = styled.button`
  ${tw`
    absolute
    flex
    text-white
    justify-center
    items-center
    w-16
    h-16
    cursor-pointer
    text-4xl
    bg-black-trans
  `}
  right: 0px;
  top: 0px;
`

const MobileContainer = styled.div`
  ${tw`
    w-full
  `}

  heigth 300px;
  & > div {
    & > div {
      & div {
        ${tw`
          pb-0
        `}
      }
    }
  }
  video {
    ${tw`
      w-full
    `}
  }
`

function VideosRow({ title, items }) {
  const totalItems = items && items.length ? items.length : 0
  const [videoPlaying, setVideoPlaying] = useState(null)
  const [currentSlide, setCurrentSlide] = useState(null)
  const [currentWidth, setCurrentWidth] = useState(
    typeof window === 'undefined' ? null : window.innerWidth
  )

  useEffect(() => {
    if (typeof window === 'undefined') return
    const handleResize = () => setCurrentWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return (
    <div
      className={classnames(
        'bg-light-grey-1',
        '-px-6',
        '-mx-6',
        'xl:mx-0',
        'md:px-0'
      )}
    >
      <Inner>
        <h2
          className={classnames(
            'pb-2',
            'pt-4',
            'md:py-5',
            'pl-0',
            'lg:pl-2',
            'font-sans',
            'text-35px',
            'leading-none',
            'font-extrabold'
          )}
        >
          {title}
        </h2>
        {totalItems ? (
          <div className="flex flex-col lg:flex-row relative">
            {items.map((item, index) => {
              return (
                <div
                  key={`item_${index}`}
                  className={classnames(
                    'w-full',
                    `lg:w-1/${totalItems}`,
                    { 'lg:bg-white': isEven(index) },
                    'flex',
                    'flex-col',
                    'justify-between',
                    'pointer-events-none',
                    'lg:pointer-events-auto'
                  )}
                >
                  <div className={classnames('cursor-pointer')}>
                    <h4
                      className={classnames(
                        'lg:pl-2',
                        'font-sans',
                        'leading-thig',
                        'xl:leading-relaxed',
                        'text-2xl',
                        'font-bold',
                        'py-0',
                        'lg:py-2',
                        'pb-1',
                        'xl:pb-0',
                        'pointer-events-none',
                        'lg:pointer-events-auto'
                      )}
                      onClick={() => {
                        setCurrentSlide(index)
                        setVideoPlaying(items[index])
                      }}
                    >
                      {item.title}
                    </h4>
                    <div
                      className={classnames(
                        'font-sans',
                        'text-base',
                        'font-thin',
                        'mx-0',
                        'lg:mx-2',
                        'pb-2',
                        'lg:pb-3',
                        'lg:px-0',
                        'leading-thig',
                        'lg:leading-normal',
                        'text-start',
                        'pointer-events-none',
                        'lg:pointer-events-auto'
                      )}
                      onClick={() => {
                        setCurrentSlide(index)
                        setVideoPlaying(items[index])
                      }}
                    >
                      {item.description}
                    </div>
                  </div>
                  <div
                    className={classnames(
                      'relative',
                      'pb-6',
                      'lg:pb-0',
                      'xs:hidden',
                      'md:hidden',
                      'lg:block',
                      {
                        'lg:hidden': videoPlaying,
                      }
                    )}
                    onClick={() => {
                      setCurrentSlide(index)
                      setVideoPlaying(items[index])
                    }}
                  >
                    <Poster src={item.poster} />
                    <PlayIcon>
                      <img src={PlayIconImage} />
                    </PlayIcon>
                  </div>
                  <MobileContainer
                    className={classnames(
                      'relative',
                      'pb-6',
                      'lg:pb-0',
                      'lg:hidden',
                      'pointer-events-auto'
                    )}
                  >
                    {currentWidth < 995 && (
                      <VideoWithTitle
                        video={item.video}
                        useBackground={false}
                        poster={item.poster_mobile}
                      />
                    )}
                  </MobileContainer>
                </div>
              )
            })}
          </div>
        ) : null}
        {videoPlaying && currentWidth > 995 && (
          <VideoContainer>
            <video
              className="pb-0"
              src={videoPlaying.video}
              poster={''}
              autoPlay
              playsInline
              controls
            />
            <CloseButton
              type="button"
              onClick={(e) => {
                e.preventDefault()
                setVideoPlaying(null)
              }}
            >
              &times;
            </CloseButton>
          </VideoContainer>
        )}
      </Inner>
      <TestimonialSlider
        key={`slider_${currentSlide}`}
        items={
          items && items.length > 0
            ? items.map((item) => {
                const {
                  author_name,
                  author_picture,
                  author_position,
                  text,
                } = item.testimonial
                return {
                  image: author_picture,
                  title: item.title,
                  quote: text,
                  author: author_name,
                  position: author_position,
                }
              })
            : []
        }
        onSlideChange={(swiper) => {
          setVideoPlaying(items[swiper.activeIndex])
        }}
        slide={currentSlide}
      />
    </div>
  )
}

export default VideosRow
